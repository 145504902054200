import React from 'react'
import {
  Box,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { Layout } from 'components/layouts/main-layout'

const useStyles = makeStyles({
  faqTitle: {
    fontWeight: 'bold',
  },
})

const AboutPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const printRules = (prefix: string, count: number) =>
    Array(count).fill(0).map((_, index: number) => (
      <Grid item xs={12} key={index}>
        <Typography paragraph>
          {t(`rules.${prefix}-${++index}`)}
        </Typography>
      </Grid>
    ))

  const printFAQ = (count: number) => Array(count).fill(0).map(
    (_, index: number) => (
      <ExpansionPanel key={index}>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography className={classes.faqTitle}>
            {t(`rules.faq-title-${(index + 1)}`)}{'?'}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t(`rules.faq-text-${(index + 1)}`)}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  )

  return (
    <Layout
      pageName={t('page.rules')}
      pageTitle={t('rules.rules')}
      narrow
      withCity={false}
    >
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5'>
              {t('rules.registration')}
            </Typography>
          </Grid>

          {printRules('reg-rule', 5)}

          <Grid item xs={12}>
            <Typography variant='h5'>
              {t('rules.participation')}
            </Typography>
          </Grid>

          {printRules('part-rule', 4)}

          <Grid item xs={12}>
            <Typography variant='h4' align='center' className={classes.faqTitle}>
              {t('rules.faq')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {printFAQ(9)}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default AboutPage
